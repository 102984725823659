<template>
  <div>
    <Filtros
      class="mb-5"
      empresas
      funcoes
      indicadores
      colaborador
      action
      @filter="getFilters"
      @input:colaborador="nomeColaborador = $event"
      @change:empresa="
        relatorio.id_empresa = $event.map(empresa => empresa.id_empresa)
      "
      @change:funcao="
        relatorio.id_funcao = $event.map(funcao => funcao.id_funcao)
      "
      @change:indicaor="
        relatorio.id_indicador = $event.map(indicador => indicador.id_indicador)
      "
    >
      <RelatorioXlsx :filter="{ ...relatorio }" realizado />
    </Filtros>
    <BaseTable
      :headers="headers"
      :items="metas.data"
      :loading="metas.loading"
      :paginate="true"
      :search="nomeColaborador"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Realizado</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        {{ colabordorInfo(item.descricao) | TitleCase }}
      </template>

      <template v-slot:[`item.valor`]="{ item }">
        {{ item.valor | BrazilianCurrency }}
      </template>
      
      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_final`]="{ item }">
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_proc`]="{ item }">
        {{ item.data_proc | BrazilianStandardDateAndTime }}
      </template>
      >
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import dateMixin from "@/mixins/dateMixin";
import currencyMixin from "@/mixins/currencyMixin";

import { mapGetters, mapActions } from "vuex";
import Filtros from "../metas/Filtros";
import RelatorioXlsx from "../RelatorioXlsx";

export default {
  name: "meta-empresa",

  mixins: [dateMixin, currencyMixin],

  components: {
    Filtros,
    BaseTable,
    RelatorioXlsx
  },

  props: {
    idTipoMeta: {
      type: Number
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Apelido",
          value: "apelido",
          sortable: true
        },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "descricao", sortable: true },
        { text: "Indicador", value: "indicador" },
        {
          text: "Valor",
          value: "valor",
          align: "right",
          sortable: true
        },
        {
          text: "Quantidade",
          value: "qtde",
          align: "right",
          sortable: true
        },
        {
          text: "Data Inicial",
          value: "data_inicio"
        },
        {
          text: "Data Final",
          value: "data_final"
        },
        {
          text: "Data de Processamento",
          value: "data_proc"
        }
      ],
      metas: {
        loading: false,
        total: 0,
        lastPage: 1,
        options: {
          page: 1,
          perPage: 15
        },
        data: []
      },
      filter: false,
      filters: {
        id_empresa: "",
        id_funcao: "",
        id_indicador: ""
      },
      nomeColaborador: "",
      relatorio: {
        id_empresa: "",
        id_tipo_meta: this.idTipoMeta,
        id_funcao: "",
        id_indicador: ""
      }
    };
  },

  mounted() {
    this.buscaMetaEmpresas();
    this.fetchIndicadores({ per_page: -1, ativa_campanha: "S" });
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getRealizadoEmpresa: "campanha/getRealizadoEmpresa"
    })
  },

  methods: {
    ...mapActions({
      fetchRealizadoEmpresa: "campanha/fetchRealizadoEmpresa",
      fetchIndicadores: "campanha/fetchIndicadores",
    }),

    async buscaMetaEmpresas() {
      this.metas.loading = true;
      try {
        await this.fetchRealizadoEmpresa({
          id_tipo_meta: 4,
          id_campanha: this.getCurrentCampanha,
          per_page: -1
        });

        this.metas.data = this.getRealizadoEmpresa.data;
        this.metas.total = this.getRealizadoEmpresa.total;
        this.metas.lastPage = this.getRealizadoEmpresa.last_page;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar os dados"
        });
        this.metas.loading = false;
      } finally {
        this.metas.loading = false;
      }
    },

    getFilters(data) {
      const { empresas, funcoes, indicadores, filter } = data;
      this.metas.options.page = 1;
      this.filter = filter;

      this.filters = {
        id_empresa: empresas,
        id_funcao: funcoes,
        id_indicador: indicadores
      };

      this.getMetasFiltradas();
    },

    async getMetasFiltradas() {
      this.metas.loading = true;

      try {
        await this.fetchRealizadoEmpresa({
          id_tipo_meta: 4,
          id_campanha: this.getCurrentCampanha,
          per_page: -1,
          ...this.filters
        });

        this.metas.total = this.getRealizadoEmpresa.total;
        this.metas.lastPage = this.getRealizadoEmpresa.last_page;
        this.metas.data = this.getRealizadoEmpresa.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possível buscar os dados"
        });
        this.metas.loading = false;
      } finally {
        this.metas.loading = false;
      }
    },

    colabordorInfo(info) {
      if (info == 0) return "Não informado";
      return info;
    }
  }
};
</script>
