var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[(_vm.empresas)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-combobox',{attrs:{"items":_vm.getEmpresasParticipantes,"item-text":"apelido","item-value":"id_empresa","label":"Empresas","full-width":"","multiple":"","clearable":"","hide-no-data":""},on:{"change":function($event){return _vm.$emit('change:empresa', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('BaseSegmento',{attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.apelido)+" ")],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.listaEmpresas.length - 1)+") "),_c('br')]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.apelido)+" ")]}}],null,false,3748107353),model:{value:(_vm.listaEmpresas),callback:function ($$v) {_vm.listaEmpresas=$$v},expression:"listaEmpresas"}})],1):_vm._e(),(_vm.funcoes)?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3"}},[_c('v-combobox',{attrs:{"items":_vm.getFuncoes,"item-text":"descricao","item-value":"id_funcao","label":"Função","full-width":"","multiple":"","clearable":"","hide-no-data":""},on:{"change":function($event){return _vm.$emit('change:funcao', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',_vm._b({key:JSON.stringify(item),attrs:{"input-value":item.selected,"small":""},on:{"click:close":function($event){return item.parent.selectItem(item)}}},'v-chip',item.attrs,false),[_c('BaseSegmento',{attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.descricao)+" ")],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.listaFuncoes.length - 1)+") "),_c('br')]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}}),_vm._v(" "+_vm._s(item.descricao)+" ")]}}],null,false,144413237),model:{value:(_vm.listaFuncoes),callback:function ($$v) {_vm.listaFuncoes=$$v},expression:"listaFuncoes"}})],1):_vm._e(),(_vm.indicadores)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-combobox',{attrs:{"items":_vm.getIndicadores,"item-text":"indicador","item-value":"id_indicador","label":"Indicador","full-width":"","multiple":"","clearable":"","hide-no-data":""},on:{"change":function($event){return _vm.$emit('change:indicador', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',_vm._b({key:JSON.stringify(item),attrs:{"input-value":item.selected,"small":""},on:{"click:close":function($event){return item.parent.selectItem(item)}}},'v-chip',item.attrs,false),[_vm._v(" "+_vm._s(item.indicador)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.listaIndicadores.length - 1)+") "),_c('br')]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.indicador)+" ")]}}],null,false,678195944),model:{value:(_vm.listaIndicadores),callback:function ($$v) {_vm.listaIndicadores=$$v},expression:"listaIndicadores"}})],1):_vm._e(),(_vm.colaborador)?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Nome do Colaborador","single-line":"","hide-details":""},on:{"input":function($event){return _vm.$emit('input:colaborador', $event)}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1","lg":"1"}},[(_vm.action)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$emit('filter', _vm.filters())}}},[_vm._v("Filtrar")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1","lg":"1"}},[_vm._t("default")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }