<template>
  <BaseTable
    :headers="headers"
    :items="items"
    :loading="loading"
    :paginate="true"
    :search="colaborador"
    class="mt-5"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Realizado Agrupado por Equipe</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
    </template>

    <template v-slot:[`item.colaborador`]="{ item }">
      {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
    </template>

    <template v-slot:[`item.funcao`]="{ item }">
      {{ item.funcao | TitleCase }}
    </template>

    <template v-slot:[`item.indicador`]="{ item }">
      {{ item.id_indicador }} - {{ item.indicador }}
    </template>

    <template v-slot:[`item.valor`]="{ item }">
      {{ item.valor | BrazilianCurrency }}
    </template>

    
    <template v-slot:[`item.data_inicio`]="{ item }">
      {{ item.data_inicio | BrazilianStandardDate }}
    </template>

    <template v-slot:[`item.data_final`]="{ item }">
      {{ item.data_final | BrazilianStandardDate }}
    </template>

    <template v-slot:[`item.data_proc`]="{ item }">
      {{ item.data_proc | BrazilianStandardDateAndTime }}
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import ServiceCampanha from "@/services/campanha";
import { mapGetters } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "MetaEquipeGrupo",

  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    colaborador: {
      type: String,
      default: ""
    }
  },

  mixins: [dateMixin, currencyMixin],

  components: {
    BaseTable
  },

  data() {
    return {
      headers: [
        {
          text: "Apelido",
          value: "apelido",
          sortable: true
        },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "descricao", sortable: true },
        { text: "Indicador", value: "indicador" },
        {
          text: "Valor",
          value: "valor",
          align: "right",
          sortable: true
        },
        {
          text: "Quantidade",
          value: "qtde",
          align: "right",
          sortable: true
        },
        {
          text: "Data Inicial",
          value: "data_inicio"
        },
        {
          text: "Data Final",
          value: "data_final"
        },
        {
          text: "Data de Processamento",
          value: "data_proc"
        }
      ],
      items: [],
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha"
    })
  },

  methods: {
    async getDataTable(filters) {
      try {
        this.loading = true;
        const { data } = await ServiceCampanha.getRealizadosEquipe({
          id_tipo_meta: 3,
          id_campanha: this.getCurrentCampanha,
          per_page: -1,
          ...filters
        });
        this.items = data;
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: "Erro ao carregar dados da tabela"
        });
      } finally {
        this.loading = false;
      }
    }
  },

  watch: {
    filters(values) {
      this.getDataTable(values);
    }
  },

  async mounted() {
    await this.getDataTable();
  }
};
</script>
