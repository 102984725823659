var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Filtros',{staticClass:"mb-5",attrs:{"empresas":"","funcoes":"","indicadores":"","colaborador":"","action":""},on:{"filter":_vm.getFilters,"input:colaborador":function($event){_vm.nomeColaborador = $event},"change:empresa":function($event){_vm.relatorio.id_empresa = $event.map(function (empresa) { return empresa.id_empresa; })},"change:funcao":function($event){_vm.relatorio.id_funcao = $event.map(function (funcao) { return funcao.id_funcao; })},"change:indicaor":function($event){_vm.relatorio.id_indicador = $event.map(function (indicador) { return indicador.id_indicador; })}}},[_c('RelatorioXlsx',{attrs:{"filter":Object.assign({}, _vm.relatorio),"realizado":""}})],1),_c('BaseTable',{attrs:{"headers":_vm.headers,"items":_vm.metas.data,"loading":_vm.metas.loading,"paginate":true,"search":_vm.nomeColaborador},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Realizado")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.colaborador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_colaborador)+" - "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(_vm.colabordorInfo(item.descricao)))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor))+" ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" ")]}},{key:"item.data_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.data_proc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDateAndTime")(item.data_proc))+" ")]}}],null,true)},[_vm._v(" > ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }