<template>
  <v-card>
    <v-card-text>
      <v-row align="center">
        <v-col v-if="empresas" cols="12" sm="2" md="2" lg="2">
          <v-combobox
            :items="getEmpresasParticipantes"
            v-model="listaEmpresas"
            item-text="apelido"
            item-value="id_empresa"
            label="Empresas"
            full-width
            multiple
            clearable
            hide-no-data
            @change="$emit('change:empresa', $event)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <BaseSegmento :segmento="item.id_band" />

                {{ item.apelido }}
              </v-chip>

              <span v-if="index === 1" class="grey--text caption">
                (+{{ listaEmpresas.length - 1 }}) <br />
              </span>
            </template>

            <template v-slot:item="{ item }">
              <BaseSegmento :segmento="item.id_band" />

              {{ item.apelido }}
            </template>
          </v-combobox>
        </v-col>

        <v-col v-if="funcoes" cols="12" sm="3" md="3" lg="3">
          <v-combobox
            :items="getFuncoes"
            v-model="listaFuncoes"
            item-text="descricao"
            item-value="id_funcao"
            label="Função"
            full-width
            multiple
            clearable
            hide-no-data
            @change="$emit('change:funcao', $event)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                :key="JSON.stringify(item)"
                v-bind="item.attrs"
                :input-value="item.selected"
                @click:close="item.parent.selectItem(item)"
                small
              >
                <BaseSegmento :segmento="item.id_band" />

                {{ item.descricao }}
              </v-chip>

              <span v-if="index === 1" class="grey--text caption">
                (+{{ listaFuncoes.length - 1 }}) <br />
              </span>
            </template>

            <template v-slot:item="{ item }">
              <BaseSegmento :segmento="item.id_band" />

              {{ item.descricao }}
            </template>
          </v-combobox>
        </v-col>

        <v-col v-if="indicadores" cols="12" sm="2" md="2" lg="2">
          <v-combobox
            :items="getIndicadores"
            v-model="listaIndicadores"
            item-text="indicador"
            item-value="id_indicador"
            label="Indicador"
            full-width
            multiple
            clearable
            hide-no-data
            @change="$emit('change:indicador', $event)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                :key="JSON.stringify(item)"
                v-bind="item.attrs"
                :input-value="item.selected"
                @click:close="item.parent.selectItem(item)"
                small
              >
                {{ item.indicador }}
              </v-chip>

              <span v-if="index === 1" class="grey--text caption">
                (+{{ listaIndicadores.length - 1 }}) <br />
              </span>
            </template>

            <template v-slot:item="{ item }">
              {{ item.indicador }}
            </template>
          </v-combobox>
        </v-col>

        <v-col v-if="colaborador" cols="12" sm="3" md="3" lg="3">
          <v-text-field
            label="Nome do Colaborador"
            single-line
            hide-details
            @input="$emit('input:colaborador', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" lg="1">
          <v-btn
            v-if="action"
            @click="$emit('filter', filters())"
            color="primary"
            >Filtrar</v-btn
          >
        </v-col>
        <v-col cols="12" sm="1" md="1" lg="1">
          <slot></slot>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";

import { mapGetters } from "vuex";

export default {
  name: "Filtros",
  components: {
    BaseSegmento
  },
  data() {
    return {
      listaEmpresas: [],
      listaFuncoes: [],
      listaIndicadores: [],
      filter: false
    };
  },
  props: {
    click: Function,
    empresas: Boolean,
    funcoes: Boolean,
    indicadores: Boolean,
    colaborador: Boolean,
    action: Boolean,
    idTipoMeta: Number
  },

  methods: {
    filtroEmpresas() {
      const values = this.listaEmpresas?.map(item => item.id_empresa);

      return values.toString() || undefined;
    },

    filtroFuncoes() {
      const values = this.listaFuncoes?.map(item => item.id_funcao);

      return values.toString() || undefined;
    },

    filtroIndicadores() {
      const values = this.listaIndicadores?.map(item => item.id_indicador);

      return values.toString() || undefined;
    },

    filters() {
      const empresas = this.filtroEmpresas();
      const funcoes = this.filtroFuncoes();
      const indicadores = this.filtroIndicadores();

      if (this.listaEmpresas.length || this.listaFuncoes.length || this.listaIndicadores.length) {
        this.filter = true;
      } else {
        this.filter = false;
      }

      return { empresas, funcoes, indicadores, page: 1, filter: this.filter };
    }
  },

  computed: {
    ...mapGetters({
      getFuncoes: "campanha/getFuncoes",
      getIndicadores: "campanha/getIndicadores",
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    })
  }
};
</script>

<style></style>
